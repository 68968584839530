<template>
  <div>
    <div>{{age}} {{required}}</div>
    <div>
      <v-btn @click="p">测试响应式</v-btn>{{hp.fate}}
    </div>
    <br>
    <p>
      测试this.$set使用方式： {{array[0].foo}}
    </p>
    <p>
      测试计算属性使用方式: {{ages}},{{ages2}}
    </p>
    <p @click="num2++">
      测试计算属性，当返回值是一个方法的调用：{{num}},{{anum}}
    </p>
    <slot name="label"></slot>
  </div>
</template>

<script>
export default {
  data() {
    let age = 666;
    let items = []
    return {
      num: 0,
      num2: 100,
      items,
      age,
      hp: {
        fate: items
      },
      obj: {},
      array: [
        {
          foo:123
        }
      ],
      lists: []
    }
  },
  props: {
    // 如果类型不是布尔，直接在组件上添加个同名属性，例如
    // <组件 required></组件>, 默认都是赋值空字符串""，
    // 如果说布尔类型，由false转true
    // required: {
    //   type: Object,
    //   default: ()=> ({})
    // }
    required: {
      type: Boolean,
      default: true
    },
    iis: {
      type: Array,
      default: ()=> []
    }
  },
  watch: {
    iis: {
      handler(v) {
        console.log(v)
      }
    }
  },
  methods: {
    p() {
      this.items.push(1);
      // console.log(this.hp.fate, this.items)
    },
    op(num) {
      console.log(num)
      return this.num;
    }
  },
  // 测试计算属性调用计算属性是否其中调用计算属性的那个计算属性可以计算
  computed: {
    ages() {
      return this.age;
    },
    // 结论可以计算
    ages2() {
      return this.ages;
    },
    anum() {
      // 计算属性中使用过的this属性，其中一个更新都会重新触发这个计算属性
      return this.op(this.num2);
    }
  },
  mounted() {
    // 用json深拷贝的对象，赋值给this属性，vue会处理添加响应式属性
    // this.obj = JSON.parse(JSON.stringify({
    //   s:123
    // }));
    this.$emit('change',555);
    setTimeout(() => {
      this.age++;
      for (const obj of this.array) {
        this.$set(obj,"foo", 456);
      }
    }, 2000);
    // 从文件上传组件中意外发现并在此文件测试得出结论：一个堆引用类型的数组或者对象，
    // 他的属性是可以修改而不触发props修改警告，除非重新赋值整个this.对象
    // this.lists = this.iis;
    // setTimeout(() => {
    //   this.lists[0] = 123;
    //   console.log(this.iis)
    // }, 1000);
  }
}
</script>

<style>

</style>