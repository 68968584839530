

<script>
export default {

}
</script>

<style>

</style>
<script>
export default {
    props: {
        // 通过这种方式简写的prop属性，他只具备类型校验，不会设置默认值
        // 所以生成的时候属性默认值是undefined
        test: String 
    },
    data() {
        return {
            af: ""
        }
    },
    render(h) {
        return h("div", {},[this.$createElement("span", {
            domProps: {
                innerText: this.af
            }
        }),h('div', [this.$slots.default])])
    },
    mounted() {
        // console.log(this.$slots)
        setTimeout(() => {
            this.af = "123";
        }, 2000);
        setTimeout(() => {
            this.af = "";
        }, 3000);
    },
    // vue2中的组件销毁回调函数，vue3里改名了
    destroyed() {
        // alert(123);
        console.log(123)
    }
}
</script>

<style>

</style>