<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          v-bind="aac(attrs)"
          v-on="on"
          @click="dialog3 = true"
        >
          Button
        </v-btn>
      </template>
      <span>Tooltip</span>
    </v-tooltip>
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="red lighten-2" dark v-bind="attrs" v-on="on">
          Click Me
        </v-btn>
      </template>
      <v-card>
        <v-card-text>
          <Form
            :deploy="deploy1"
            :vmm="vmm"
            ref="poo"
            :key="pp"
            :dialog="true"
          ></Form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <formMenu
      :dialog.sync="dialog3"
      @validateSave="pos"
      :listDeploy="deploy2"
      
    ></formMenu>
    <!-- <div style="padding:12px;width:200px;">
        <wautocomplete :items="items2"></wautocomplete>
      </div> -->
    <!-- <div style="width:400px"><wfileinput></wfileinput></div> -->
    <study></study>
    <exhibitionDataBox :dialog.sync="dialog2"></exhibitionDataBox>
    <!-- <editorText></editorText> -->
    <p></p>
    <v-btn @click="homs">回到主页</v-btn>
    <p></p>
    <v-btn @click="dialog3 = !dialog3" ref="tgb">打开表单例子</v-btn>
    <div>
      <input name="file" type="file" accept="image/gif,image.jpg" @change="p" />
      <img :src="url" alt="" />
    </div>
    <p>
      <v-text-field></v-text-field>
    </p>
    <p @click="show = !show">测试tinymce显示bug</p>
    <!-- <tinymces v-if="show"></tinymces> -->
    <w-switches v-model="value"></w-switches>{{ value }}
    <rabbet2 required :iis="items2"></rabbet2>
    8
    <rabbet ref="rabbet" v-if="show">
      <div>1239</div>
    </rabbet>
    <test-model v-model="searchText"></test-model>{{searchText}}

  </div>
</template>

<script>
import Form from "@/components/dynamicForm/dynamicForm.vue";
import rabbet from "./rabbet.vue";
import rabbet2 from "./rabbet2.vue";
import formMenu from "@/components/form-menu/form-menu.vue";
import wautocomplete from "@/components/w-autocomplete/w-autocomplete.vue";
import wfileinput from "@/components/w-file-input/w-file-input";
import study from "./study-file-input.vue";
import exhibitionDataBox from "@/components/exhibition-data-box/exhibition-data-box.vue";
import editorText from "@/components/editorText/editorText.vue";
import tinymces from "@/components/tinymces/tinymces.vue";
import wtabs from "@/components/w-tabs/w-tabs.vue";
import testModel from './testModel.vue';
export default {
  components: {
    Form,
    rabbet,
    rabbet2,
    formMenu,
    wautocomplete,
    wfileinput,
    study,
    exhibitionDataBox,
    editorText,
    tinymces,
    wtabs,
    testModel
  },
  // 指令注册
  directives: {
    pin: {
      bind: (el, binding) => {
        el.style.position = "fixed";
        // 通过参数动态设置top像素
        el.style.top = binding.value + "px";
      },
    },
  },

  data() {
    let obj = {
      pls: "div",
      value: "123",
      get text() {
        return this.value;
      },
      set text(v) {
        this.value = v;
      },
    };
    return {
      searchText: '0',
      pp: 0,
      hj: {
        name: "show",
        value: true,
      },
      show: true,
      obj,
      value: false,
      // items: ['foo', 'bar', 'fizz', 'buzz'],
      pls: "div",
      items2: [
        { code: "foo", desc: "富" },
        { code: "bar", desc: "酒吧" },
      ],
      dialog: false,
      dialog2: false,
      dialog3: false,
      deploy1: {
        row: [
          {
            cols: 6,
            subassembly: "div",
            default: "888",
            connector: {
              on: {
                click: () => {
                  this.deploy1.row[1].connector.style.display = "none";
                  this.pp++;
                  console.log(this.deploy1)
                },
              },
            },
            slots: (fn)=> {
              return fn('span', {slot:'label'}, 456)
            }
          },
          {
            cols: 6,
            subassembly: "VTextField",
            connector: {
              style: {
                display: "block",
              },
              props: {
                color: "primary",
                size: "56",
                counter: 25
              },
              scopedSlots: {
                counter: () => {
                  return this.$createElement("span", {}, 123);
                },
              },
            },
            // slots: (fn)=> {
            //   return [fn('span', {slot:'label'}, 456)]
            // }
            slots: function (h) {
              return [h("span", { slot: "label" }, 456)];
            },
          },
          {
            cols: 12,
            subassembly: "WFileinput",
            property: "p",
            connector: {
              props: {
                uniline: true,
                notbtn: true,
                required: true,
                hint: "123",
                label: '88',
                // items: `https://ossfile.citybaytech.com/001/roomType/2021/12/08/1bba48efeb464efb9675381bbb52e9e1.mp4`,
                items: `https://ossfile.citybaytech.com/001/roomType/2021/11/10/29348b1bcdc44d7b999bd9e4c778c4a5.jpg`
                // min: 3
              },
              on: {
                click: ()=> {
                  console.log('WFileinput')
                }
              }
            },
          },
          {
            cols: 12,
            subassembly: "WAutocomplete",
            connector: {
              nativeOn: {
                click: ()=> {
                  console.log(77)
                }
              },
              props: {
                items: ['foo','bar']
              }
            }
          }
          // {
          //   cols: 6,
          //   subassembly: rabbet2,
          //   connector: {
          //     scopedSlots: {
          //       label: ()=> {
          //         return 1239
          //       }
          //     },
          //   },
          //   slots: (fn)=> {
          //     return fn('span', {slot:'label'}, 456)
          //   }
          // },
        ],
      },
      deploy3: {},
      deploy2: {
        row: [
          {
            cols: 6,
            subassembly: "div",
            connector: {
              on: {},
              props: {
                label: "sss",
                required: true,
              },
            },
          },
          {
            cols: 0,
            subassembly: "template",
            property: "yge",
            connector: {
              class: "asterisk",
              props: {
                required: true,
                label: "123",
                multiple: true,
                chips: true,
                value: "",
                // someToValue: 'desc',
                items: [
                  {
                    desc: "命运之夜",
                    code: "fate",
                  },
                  {
                    desc: "命运-冠位指定",
                    code: "fgo",
                  },
                  {
                    desc: "时间管理局",
                    code: "tva",
                  },
                ],
              },
            },
          },
          {
            cols: 6,
            subassembly: "VSwitch",
            property: "jiba",
            connector: {
              props: {},
              directives: [
                {
                  name: "model",
                  value: this.value,
                },
              ],
            },
            global: true,
            default: true,
          },
          // {
          //   cols: 12,
          //   subassembly: "WFileinput",
          //   property: "p",
          //   connector: {
          //     props: {
          //       accept: "image/png, image/jpeg, image/svg+xml",
          //       uniline: true,
          //       notbtn: true,
          //       required: true,
          //       hint: "123",
          //       label: '88',
          //       items: ``,
          //       // min: 3
          //     },
          //   },
          //   default:
          //     "https://datascenic.oss-cn-hangzhou.aliyuncs.com/001/roomType/2021/10/11/3520d51e49f048a3b9ef67495c6703c1.mp4",
          // },
          // {
          //   cols: 12,
          //   subassembly: editorText,
          //   property: "fwb",
          //   connector: {
          //     props: {
          //       required: false,
          //       value: `<h1>嘿嘿嘿</h1>`,
          //       menubar: true,
          //     },
          //     on: {},
          //   },
          // },
          // {
          //   cols: 12,
          //   subassembly: tinymces,
          //   property: "fcc",
          //   connector: {
          //     props: {
          //       value: `<h1>tinymce</h1>`,
          //     },
          //   },
          // },
          {
            cols: 12,
            subassembly: wtabs,
            connector: {
              props: {
                items: ["购买规则", "售后规则"],
                deploy: [
                  {
                    row: [
                      {
                        cols: 6,
                        subassembly: "div",
                        default: "888",
                        connector: {},
                      },
                      {
                        cols: 6,
                        subassembly: "VTextField",
                        property: "fcc",
                        connector: {
                          on: {},
                          props: {
                            required: true,
                            label: "sss",
                          },
                        },
                      },
                    ],
                  },
                  {
                    row: [
                      {
                        cols: 6,
                        subassembly: "div",
                        default: "888",
                        connector: {},
                      },
                      {
                        cols: 6,
                        // subassembly: rabbet2,
                        subassembly: 'VTextField',
                        property: "fss",
                        connector: {
                          on: {},
                          props: {
                            required: true,
                            label: "sss",
                          },
                        },
                      },
                    ],
                  },
                ],
              },
            },
          },
        ],
      },
      vmm: "456",
      url: "",
    };
  },
  mounted() {
    
    setTimeout(() => {
      this.value = true;
      this.obj.pls = "VTextField";
      console.log(this.value);
      this.show = false;
    }, 2000);
    console.log(this.$refs.rabbet.test);
    let a = "https://ossfile.citybaytech.com/001/roomType/2021/11/10/98963ebeecf844928ee3e21d67b1a615.png";
    let b = "https://ossfile.citybaytech.com/001/roomType/2021/11/23/b8ac996cbe0b445583510de155d0950a.jpg";
    // this.axios.get("https://ossfile.citybaytech.com/001/roomType/2021/11/08/a53705917ede46db98f2a9bf20fadc02.png",{responseType: 'blob'}).then(res=> {
    //   console.log(res,'res')
    // }).catch(err=> {
    //   console.log(err,'err')
    // })
    // setTimeout(() => {
    //   this.dialog3 = true;
    //   this.deploy2.row[1].connector.props.items = [
    //     {
    //       code: "HOTELTAGS1",
    //       desc: "有停车位",
    //     },
    //     {
    //       code: "HOTELTAGS2",
    //       desc: "近学校",
    //     },
    //   ];
    //   this.deploy2.row[1].connector.props.value = [];
    // }, 3000);
  },
  methods: {
    aac(v) {
      console.log(v)
      return v;
    },
    p(inputs) {
      let reads = new FileReader();
      let input = inputs.path[0];
      input = input.files[0];

      reads.readAsDataURL(input);
      let that = this;
      reads.onload = function (e) {
        that.url = this.result;
        console.log(e, this.result);
      };
    },
    homs() {
      this.$router.push({ path: "/setmeal-manage" });
    },
    pos(val, callback) {
      // console.log(callback())
      return 999;
    },
    downFile(src) {
      let me = this;
      var xhr = new XMLHttpRequest();
      xhr.open("GET", src);
      xhr.responseType = "blob";
      //二进制文件
      xhr.onload = function () {
        console.log(this.response, "response");
        var filename = src.slice(src.lastIndexOf(".") + 1, src.length);
        var arr = src.split(","),
          mime = arr[0].match(/:(.*?);/)[1];
        var blob = this.response;
        return blob;
        //blob转file
        me.postImg(file, src);
        console.log("file", file);
        if (this.status === 200) {
          var img = document.createElement("img");
          img.src = window.URL.createObjectURL(this.response);
          img.onload = function () {
            //图片加载完，释放一个URL资源.
            window.URL.revokeObjectURL(this.src);
          };
          document.body.appendChild(img);
        }
      };
      xhr.send();
    },
  },
};
</script>

<style>
</style>