<template>
  <div>
      :{{value}}
      <!-- 在vue2中，双向绑定指令只作用props的value属性 -->
      <!-- 在vue2的render函数中使用双向绑定，就监听input事件，然后赋值给
      指定的props属性 -->
    <input
      v-bind:value="value"
      v-on:input="$emit('input', $event.target.value)"
    />
  </div>
</template>

<script>
export default {
  props: {
      value: String
  },
  data() {
    return {

    };
  },
};
</script>