<template>
  <div>
      <input type="file" id="input" onchange="handleFiles(this.files)">
      <img src="" alt="" id="">
  </div>
</template>

<script>
window.handleFiles = function (e) {
    // 文件列表api，每次选择文件都是覆盖键值0的文件对象
    // 除非是批量选择，键值0以外才会有新对象
    console.log(e)
}
export default {
    data() {
        return {

        }
    },
    methods: {
        handleFiles(e) {
            console.log(e.files)
        }
    }
}
</script>

<style>

</style>